@import 'includes';

/* https://markdotto.com/2018/02/07/github-system-fonts/ */
/* $fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */

$headerfonts: abril-display, serif;
$bodyfonts: freight-sans-pro, sans-serif;

html {
  scroll-behavior: smooth;
}

body, input.post-comment-field, textarea.post-comment-field, .btn-reply, .btn-reply-thread, button.comment-buttons, button.menu {
  font-family: $bodyfonts;
  color: #444;
  font-size: 22px;
  line-height: 1.6;
}

p {
  margin: 1.25em 0;
}

em, i {
  font-style: italic;
}

strong, b {
  font-weight: bold;
}

h1, h2, h3, h4 {
  font-family: $headerfonts;
  font-weight: bold;
  margin: 1.5em 0 1em 0;
  line-height: 1.15em;
}

h5, h6 {
  font-weight: normal;
  margin: 1.5em 0 1em 0;
  line-height: 1em;
}

h1 {
  font-size: 1.625em;
}
h2 {
  font-size: 1.375em;
}
h3 {
  font-size: 1.125em;
}
h4 {
  font-size: 1em;
}
h5 {
  font-size: 0.875em;
}
h6 {
  font-size: 0.75em;
}

h4, h5, h6 {
  letter-spacing: 1px;
  margin-top: 2.5em;
}

a {
  color: #9E1414;
  transition: color 0.1s ease;
  text-decoration: underline;

  &:hover {
    color: #9E1414;
    text-decoration: none;
  }
}

a[href^="http"]::after,
a[href^="https://"]::after {
	content: "";
	width: 0.6em;
	height: 0.6em;
	margin-left: 0.25em;
	background-image: url("/site-images/external-link3.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
}

a[href^="http://localhost:1313"]::after
{
  display: none !important;
}

a[href^="https://benwfey.com"]::after
{
  display: none !important;
}

ol li {
  list-style: decimal;
  margin-left: 2.25em;
  margin-bottom: 0.5em;
}

ul li {
  list-style: disc;
  margin-left: 2.25em;
  margin-bottom: 0.5em;
}

dt {
  font-weight: bold;
}
dd {
  margin-left: 1.25em;
}

hr {
  margin: 2em 0;
  border: 0;
  border-top: 2px dotted #aaa;
}

pre, code {
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  border-radius: 3px;
}
code {
  background: #f9f9f9;
  font-size: 0.9em;
  padding: 0.25em 0.5em;
  color: #9E525A;
}
pre code {
  background: #f9f9f9;
  display: block;
  margin: 1em 0;
  padding: 0.75em 1em;
  color: inherit;
}

.metadata {
  font-size: 0.875em;
  letter-spacing: 1px;
  line-height: 1em;
}

.imgcenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.imgcenterborder {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #cccccc;
}

.column30 {
  float: left;
  width: 30%;
  padding-right: 5px;
}
.column70 {
  float: left;
  width: 70%;
  padding-right: 5px;
}
/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}

img, video {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.image-box {
  border: 1px solid #bbbbbb;
}

figure {
  width: 100%;
  margin: 2em 0;
  text-align: center;
}
figcaption {
  p {
    margin: 0;
  }
  text-align: center;
  font-size: 0.9em;
  color: #666;
}

blockquote {
  padding-left: 1.25em;
  padding-right: 1.5em;
  border-left: .25em solid #eee;
  color: #666;
}

table {
  margin-bottom: 1em;
  width: 100%;
  border: 1px solid #ddd;
}

td, th {
  vertical-align: middle;
  padding: .25rem .5rem;
  border: 1px solid #ddd;
}

th {
  font-weight: bold;
}

tbody tr:nth-child(2n+1) td, tbody tr:nth-child(2n+1) th {
  background-color: #f8f8f8;
}

sup, sub {
  font-size: 0.8em;
}
sup {
  vertical-align: super;
}

main, .site-logo-nav, .horizontal-navlinks {
  max-width: 740px;
  padding: 0 20px;
  margin: 0 auto;
}

header h2 {
  /* margin: 1em; */
  a {
    text-decoration: none;
    border: none;
    color: #333333 !important;

    &:hover {
      color: #9E1414 !important;
    }

    &:active {
      color: #333 !important;
    }
  }
}

.horizontal-navlinks {
  max-width: 740px;
  margin-top: 0;
  margin-bottom: 0.25em;
  a {
    border-right: 2px solid #ccc;
    padding-right: 0.5em;
    margin-right: 0.5em;
  }
  a:last-child {
    border: none;
  }
  &:last-child {
    margin-bottom: 1.25em;
  }
}

header {
	background-color: #ffffff;
}

header p {
  margin: 0 0 1.25em 0;
}
.site-logo-nav img {
width: 100px;
border: 1px solid #000000;
margin: 2.5em 0 0.75em 0;
}

  .main-logo {
  display: block;
  }
  .alt-logo {
  display: none;
  }

.site-logo-nav p {
font-size: 0.875em;
}

.combined-title-pagination {
  margin-top: 2.5em;
  h1 {
    display: inline;
    a {
      border: none;
    }
  }
  h3 {
    display: inline;
  }
}

.title-group {
  .title h1 {
    margin-bottom: 0.5em;
  }
  .date .metadata {
    color: #555555;
    margin: 0;
  }
  margin-bottom: 1.5em;
}

footer {
border-top: 1px solid #cccccc;
  margin-top: 3em;
  .content-container {
    background: #eee;
    padding: 2em 0;
    .content {
      max-width: 740px;
      padding: 0 20px;
      margin: 0 auto;
    }
  }
}

footer::after {
    position: fixed;
    background: #eee;
    height: 100%;
    width: 100%;
    content: ""; 
}

.horizontal-links {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0.25em;
  a {
    border-right: 2px solid #ccc;
    padding-right: 0.5em;
    margin-right: 0.5em;
  }
  a:last-child {
    border: none;
  }
  &:last-child {
    margin-bottom: 1.25em;
  }
}

.post-list {
  h1 a {
    color: #333;
    text-decoration: none;
    border: none;

    &:hover {
      color: #9E1414;
      text-decoration: underline;
    }
  }
  .read-more {
    margin-bottom: 1.25em;
  }

  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
}

.tags {
  font-size: 0.9em;
  .links {
    display: inline;
    margin-left: 0.5em;
    a {
      color: #555555;
      text-decoration: none;
      border-bottom: none;
      &:hover {
        color: #555555;
        text-decoration: underline;
      }
    }
  }
}

.paginator {
  margin: 2em 0;
  .disabled {
    color: #aaa;
  }
  .newer {
    float: right;
    clear: both;
  }
}

.current-page {
  color: #555555;
}

.card {
	margin: 3em 0 0 0;
	padding: 1em;
	background-color: #eeeeee;
	border: 1px solid #cccccc;
	border-radius: 5px;
}

.card-h1 {
    margin: 1.25em 0 0 0;
}

.photocard-h1 {
    margin: 1em 0 0.75em 0;
}

.card-p {
	margin: 0.5em 0 1.25em 0;
}

/* Drop Cap ! */
.has-drop-cap:not(:focus)::first-letter {
	font-family: $headerfonts;
	color: #9E1414;
	font-size: 3.5em;
	font-weight: 700;
	margin: 0.23em 0.05em 0 0;
	float: left;
    line-height: .68;
    text-transform: uppercase;
}

.has-drop-cap:not(:focus)::first-letter::after {
	content: "";
	display: table;
	clear: both;
}

.has-drop-cap:not(:focus)::after {
	padding: 0;
}


/* Expand Collapse Button  */

.collapsible.nojs {
  background-color: #444444;
  color: white;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-family: $bodyfonts;
  font-size: 1em;
  cursor: default;
}

.collapsible.nojs:hover {
  background-color: #444444;
}

.collapsible.nojs:before {
  content: '' !important;
  margin-right: 0;
}

.collapsiblecontent.nojs {
  padding: 2em;
  display: block;
  overflow: hidden;
  background-color: #f1f1f1;
}

.collapsible {
  background-color: #444444;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-family: $bodyfonts;
  font-size: 1em;
}

.collapsible:hover {
  background-color: #6E6E6E;
}

.collapsible:before {
  content: 'Show:';
  margin-right: 10px;
}

.collapsible.hide:before {
  content: 'Hide:';
  margin-right: 10px;
}

.collapsiblecontent {
  padding: 2em;
  display: block;
  overflow: hidden;
  background-color: #f1f1f1;
}

.collapsiblecontent.hidden {
  display: none;
}

.collapse-container {
  margin: 1.25em 0 1.25em 0;

}

/* END Collapse */


.album {
  .date, .date .metadata, .location, .location .metadata {
    display: inline;
  }
  .location {
    color: #555555;
    border-left: solid 2px #ccc;
    padding-left: 1em;
    margin-left: 1em;
  }
  .photos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.7em 0.7em;
    a {
      display: grid;
    }
    a.full-size {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

@media (max-width:400px) {
  body {
    font-size: 20px;
  }
  .album .photos {
    grid-template-columns: 1fr;
    a {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
  .column30 {
  width: 100%;
}
.column70 {
  width: 100%;
}
/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}
}

/* Responsive Top Navigation */
.topnav {
  overflow: hidden;
  margin: 0 0 1.25em 0;
}
.topnav a {
  float: left;
  display: block;
  text-align: center;
  border-right: 2px solid #ccc;
  padding-right: 0.5em;
  margin-right: 0.5em;
}
.topnav a:last-child {
  border: none;
}
.topnav button {
  display: none;
  margin: 0;
}

/* Comments */

.form {
margin-top: 2em;
padding: 1em;
border: 1px solid #cccccc;
background: #eeeeee;
border-radius: 5px;
}

.post-comment-field {
width: 100%;
border: 1px solid #cccccc;
padding: 0.5em;
margin: 0 0 1em 0;
border-radius: 5px;
}

.form-hp {
visibility: hidden;
height: 0;
}

.post-comment {
border: 1px solid #cccccc;
padding: 0.75em;
margin: 0 0 1.5em 0;
background-color: #eeeeee;
border-radius: 5px;
}

.post-comment.reply {
margin-left: 2.5em;
border-left: 10px solid #cccccc;
}

.comment-buttons {
width: fit-content;
background-color: #444444;
color: #ffffff !important;
padding: 0.4em 0.8em;
border: none;
cursor: pointer;
font-weight: 400;
-webkit-appearance: none;
}

.comment-buttons.loading {
border-radius: 5px;
width: 8.5em;
margin: 0 0 1em 0;
}

.comment-buttons:disabled {
background-color: #cccccc;
color: #333333 !important;
}

.comment-buttons.loading:disabled {
background-color: #cccccc;
color: #333333 !important;
animation-name: loading;
animation-duration: 1.5s;
animation-iteration-count: infinite;
animation-timing-function: ease-in-out;
animation-direction: alternate;
}

@keyframes loading {
0% {background-color: #cccccc;}
100% {background-color: #cccccc; color: #bbbbbb;}
}

.comment-buttons:disabled:hover {
background-color: #cccccc;
cursor: not-allowed;
}

.comment-buttons.hide {
display: none;
}

.btn-reply.nojs, .btn-reply-thread.nojs {
display: none;
}

.btn-reply, .btn-reply-thread {
font-size: 0.9em;
color: #9E1414;
}

.btn-reply:hover, .btn-reply-thread:hover {
text-decoration: none;
background: none;
}

.comment-buttons:hover,  {
background-color: #6E6E6E;
}

.post-comment-header {
}

.post-comment-info {
padding-bottom: 1em;
}

.post-comment-name {

}

.post-comment-name h4 {
margin: .5em 0 0 0;
}

.post-time {
font-size: 0.9em;
float: right;
margin-left: 1.5em;
}

.post-time:hover {
text-decoration: underline;
}

.in-reply {
font-size: 0.9em;
font-style: italic;
}

.comment-message {
width: 100%;
border-top: 1px solid #bbb;
display: inline-block;
padding: 1em 0 1em 0;
}

.replymessageto h4 {
margin: .5em 0 0 0;
}

.cancel {
margin-bottom: 1em;
}

/* Comments Dialog */

.dialog {
   display: none;
   position: fixed;
   background-color: rgb(247, 247, 247);
   padding: 20% 2em 0 2em;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   text-align: center;
}

.dialog:target {
   display: block;
}
.dialog h3{
   font-size: 25px;
   font-weight: bold;
}

.dialog .comment-buttons.ok {
   padding: 0.4em 2em;
   display: inline-block;
   text-decoration: none;
   border-radius: 5px;
}
